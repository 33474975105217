@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row-pointer {
  cursor: pointer;
}

.test-result {
  &__positive {
    color: red !important;
  }
  &__negative {
    color: green !important;
  }
  &__invalid {
    color: orange !important;
  }
}

.logo {
  margin: 0.5rem;
  font-weight: 700;
}
